import React, { Component } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import moment from "moment";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Share from "../components/share";
import { number } from "prop-types";

export default class course extends Component {
    render() {
        const data = this.props.data.contentfulCourses;
        const siteurl = this.props.data.contentfulSiteInformation.siteUrl + "/";
        const twiteerhandle = this.props.data.contentfulSiteInformation
            .twiteerHandle;
        const socialConfigss = {
            site: {
                siteMetadata: { siteurl, twiteerhandle }
            },
            title: data.title,
            slug: data.slug
        };

        return (
            <Layout >
                <SEO
                    title={data.title}
                    keywords={[
                        `Mohsen Jalali`,
                        `Frontend Developer`,
                        `Developer`,
                        `${data.title}`,
                        , `محسن جلالی`
                    ]}
                />
                <div className="site-container blog-post" >
                    <div className="container">
                        <Img
                            className="feature-img"
                            fixed={data.featureImage.fluid}
                            objectFit="cover"
                            objectPosition="50% 50%"
                        />
                        <div className="details" >
                            <h1 className="title">{data.title}</h1>
                            <span className="date">
                                <i class="fas fa-calendar-alt"></i>{" "}
                                {moment(data.createdAt).format("LL")}
                            </span>
                            <div className="farsi "
                                dangerouslySetInnerHTML={{
                                    __html: data.description.childMarkdownRemark.html
                                }}
                            ></div>
                            <div>
                                {data.video.map((url,number) => (
                                    <div class="video-container farsi">
                                        <h4 class="farsi"> جلسه {number+1}</h4>
                                        <iframe width="560" height="315" src={url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                ))}
                            </div>
                        </div>


                        <Share
                            socialConfig={{
                                ...socialConfigss.site.siteMetadata.twiteerhandletitle,
                                config: {
                                    url: `${siteurl}${socialConfigss.slug}`,
                                    title: `${socialConfigss.title}`
                                }
                            }}
                        />
                    </div>
                </div>
            </Layout>
        );
    }
}

export const pageQuery = graphql`
  query SinglecourseQuery($slug: String!) {
    contentfulCourses(slug: { eq: $slug }) {
      id
      title
      slug
      video
      featureImage {
        fluid(maxWidth: 1500) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      createdAt
    }
    contentfulSiteInformation {
      siteUrl
      twiteerHandle
    }
  }
`;
